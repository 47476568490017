import React from "react"

const Keywords = ({ id, keywords }) => {
    return(
        <div id={id} className="sr-only">
            {keywords.join(', ')}
        </div>
    )
}

export default Keywords